<template>
<div>
    <v-switch v-if="debug && allowAccess" v-model="ui.editor" :true-value="true" :false-value="false" label="Editor"/>
    <div v-show="!selectedEvent">
        <Filters v-if="ready" v-model="form.filters" @clear="ui.filterDates=null" @filter="filterEvents" :autocompleteItems="autocompleteItems" style="margin-bottom: 40px;" :language="language"/>
        <v-container v-if="ready" style="max-width: none;">
            <v-row>
                <v-col class="pa-0">
                    <v-toolbar flat class="tabContainer">
                        <v-tabs height="67" :hide-slider="true" v-model="ui.language" centered>
                            <v-tab @click="$router.push({name:'Events',params:{language:lang}})" :href="'#'+lang" v-for="lang in languages" :key="'language_tab_'+lang">
                                <str :index="`events.tabs.${lang=='en-ca' ? 'en' : 'fr'}`"/>
                            </v-tab>
                        </v-tabs>
                    </v-toolbar>

                    <!-- <div v-show="false" style="width: 100%; text-align: center;margin-top: 50px; display: flex; justify-content: center;">
                        <div class="filterDates-button" :class="[{'active':!form.filters.upcoming && !form.filters.past}]" @click="toggleMyEvents" text>
                            <div>My Events</div>
                        </div>
                        <div class="filterDates-button" :class="[{'active':ui.filterDates=='Today'}]" @click="filterDates('Today')" text>
                            <div><Str index="events.filters.today._text" :language="ui.language ? ui.language : 'en-ca'"/></div>
                        </div>
                        <div class="filterDates-button" :class="[{'active':ui.filterDates=='This Week'}]" @click="filterDates('This Week')" text>
                            <div>This Week</div>
                        </div>
                        <div class="filterDates-button" :class="[{'active':ui.filterDates=='Next Week'}]" @click="filterDates('Next Week')" text>
                            <div>Next Week</div>
                        </div>
                        <div class="filterDates-button" :class="[{'active':ui.filterDates=='This Month'}]" @click="filterDates('This Month')" text>
                            <div>This Month</div>
                        </div>
                    </div> -->

                    <v-tabs-items v-model="ui.language">
                        <v-tab-item active-class="selected-tab" style="width: 100%; margin: 0 auto;" :eager="true" :value="lang" v-for="lang in languages" :key="'language_tab_item_'+lang">
                            <div v-show="isAllTrue(initializedEvents)">
                                <template v-for="section in config.event_sections">
                                    <template v-if="$store.getters.signedIn || section=='upcoming'">
                                        <v-expand-transition :key="'events_section_'+section">
                                            <div :class="'events-section-'+section">
                                                <v-container style="margin-top: 15px;">
                                                    <v-row align="center" style="height: 120px;">
                                                        <v-col align="center">
                                                            <div class="events-state-container">
                                                                <div class="events-state-treatment"/>
                                                                <div class="events-state-title">
                                                                    <Str :index="'events.'+section+'._text'"/>
                                                                </div>
                                                            </div>
                                                        </v-col>
                                                    </v-row>
                                                </v-container>

                                                <div :key="section+'_program_content'" class="events-container">
                                                    <template v-if="filteredEvents[lang][section].enrolled.length>0">
                                                        <template v-for="(eventData, eventIndex) in filteredEvents[lang][section].enrolled">
                                                            <!-- <div v-if="debug" :key="'debug_'+eventIndex">
                                                                <v-btn small text @click="enrollment_selection=eventData;ui.enrollment_success.display=true">Enroll Success</v-btn>
                                                            </div> -->

                                                            <EventCard v-show="eventData.cancelled==='N'"
                                                                @initialized="EventCardInitialized"
                                                                :key="'enrolled_event_card_'+section+'_'+eventIndex+'_'+eventData.program_id+'_'+eventData.id"
                                                                v-model="eventMap[eventData.id]"
                                                                :buttons="{
                                                                    startEvaluation: {
                                                                        rules: {
                                                                            attended: enrollmentMap[eventData.id] ? enrollmentMap[eventData.id].attended=='Y' : false,
                                                                            enrolled: enrolled ? enrolled.includes(eventData.id) : false,
                                                                            finished: eventData.state=='finished',
                                                                            missingEval: eventData.postEventQuestions && !evaluations.includes(eventData.id)
                                                                        },
                                                                        label: {
                                                                            'en-ca':'Evaluation','fr-ca':'Évaluation'
                                                                        },
                                                                        doThis: startEvaluation
                                                                        }
                                                                    }"
                                                                :debug="debug"
                                                                @minutes_away="notify_starting"
                                                                @started="notify_started"
                                                                @more_details="more_details"
                                                                @enroll="enroll"
                                                                @withdraw="withdraw"
                                                                :language="ui.language ? ui.language : 'en-ca'"
                                                                :enrolled="enrolled.includes(eventData.id)"
                                                                :enrollmentRecord="enrollmentMap[eventData.id] ? enrollmentMap[eventData.id] : null"
                                                                :program="programMap[eventData.program_id]"
                                                                :missingEval="!postEvent[eventData.id].evaluation && enrollmentMap[eventData.id].attended=='Y'"
                                                                :speakers="speakers"
                                                                :options="config.eventCard.options"
                                                                :bookings="bookings[eventData.id]"
                                                                class="events-card"
                                                            >
                                                                <template v-slot:actionButtons>
                                                                    <template v-if="section=='upcoming'">
                                                                        <v-btn color="green" style="color:white !important;" v-if="debug || ['minutes_away','started'].includes(eventData.state)" @click="openZoom(eventData.zoom.join_url)" target="_blank">
                                                                            <str index="events.buttons.join._text"/>
                                                                        </v-btn>
                                                                        <v-btn v-else color="green" style="color:white !important;">
                                                                            <str index="events.buttons.enrolled._text"/>
                                                                        </v-btn>
                                                                        <v-btn @click="more_details(eventData)">
                                                                            <str index="events.buttons.details._text"/>
                                                                        </v-btn>
                                                                    </template>
                                                                </template>

                                                                <template v-slot:resourceButtons>
                                                                    <v-container v-if="section=='upcoming'" class="resource-btn-container">
                                                                        <v-row no-gutters>
                                                                            <v-col cols="7" class="d-flex justify-start" style="padding-left: 5px; border-right: 1px solid #b1c3b5;">
                                                                                <!--
                                                                                <v-btn :disabled="!debug && !['minutes_away','started'].includes(eventData.state)" :href="eventData.zoom.join_url" target="_blank">
                                                                                    <str index="events.buttons.join._text"/>
                                                                                </v-btn>
                                                                                -->
                                                                                <v-btn class="cta white--text" @click="sendRequest('get',`/api/event/ics/${Hasher.encode(eventData.id)}/${$store.getters.user.signInUserSession.accessToken.jwtToken}`)">
                                                                                <!-- <v-btn :href="`/api/event/ics/${Hasher.encode(eventData.id)}/${$store.getters.user.signInUserSession.accessToken.jwtToken}`" target="_blank"> -->
                                                                                    <!-- <v-icon left>mdi-calendar-export</v-icon> -->
                                                                                    <str index="events.buttons.ics._text"/>
                                                                                </v-btn>
                                                                            </v-col>
                                                                            <v-col cols="5" class="align-center d-flex justify-center" style="text-align: left;">
                                                                                <!--
                                                                                <template v-if="ui.withdraw!=eventData.id" >
                                                                                    <div class="d-flex withdraw-container" @click="ui.withdraw=eventData.id">
                                                                                        <v-icon color="red">mdi-cancel</v-icon>
                                                                                        <str index="events.buttons.withdraw._text"/>
                                                                                    </div>
                                                                                </template>
                                                                                    -->
                                                                                    <v-btn plain v-if="ui.withdraw!=eventData.id" @click="ui.withdraw=eventData.id" class="withdraw-btn">
                                                                                        <v-icon color="red">mdi-cancel</v-icon>
                                                                                        <str style='text-wrap:wrap; width:min-content;margin-left:4px;' index="events.buttons.withdraw._text"/>
                                                                                    </v-btn>
                                                                                <template v-else>
                                                                                    <v-btn color="error" style="color: white !important;" :disabled="ui.busy" @click="withdraw(eventData)">
                                                                                        <str index="buttons.confirm._text"/>
                                                                                    </v-btn>
                                                                                    <v-btn @click="ui.withdraw=null">
                                                                                        <str index="buttons.cancel._text"/>
                                                                                    </v-btn>
                                                                                </template>
                                                                            </v-col>

                                                                        </v-row>


                                                                    </v-container>

                                                                    <template v-if="section=='past'">
                                                                        <div style="margin-top: 30px;">
                                                                            <v-btn color="green" style="color: white !important;" v-if="enrollmentMap[eventData.id].attended=='Y' && postEvent[eventData.id].completed===false" @click="startEvaluation(eventData)">
                                                                                <str index="events.buttons.evaluation._text"/>
                                                                            </v-btn>
                                                                            <v-btn v-else-if="enrollmentMap[eventData.id].attended=='Y' && enrollmentMap[eventData.id].step_states && isAllTrue(enrollmentMap[eventData.id].step_states)" @click="startEvaluation(eventData)">
                                                                                <str index="events.buttons.certificate._text"/>
                                                                            </v-btn>
                                                                            <v-btn @click="more_details(eventData)">
                                                                                <str index="events.buttons.details._text"/>
                                                                            </v-btn>
                                                                        </div>
                                                                    </template>
                                                                </template>

                                                            </EventCard>
                                                        </template>
                                                    </template>


                                                    <template v-if="filteredEvents[lang][section].not_enrolled.length>0">
                                                        <template v-for="(eventData, eventIndex) in filteredEvents[lang][section].not_enrolled">
                                                            <EventCard v-show="eventData.cancelled==='N'"
                                                                @initialized="EventCardInitialized"
                                                                :key="'not_enrolled_event_card_'+section+'_'+eventIndex+'_'+eventData.program_id+'_'+eventData.id"
                                                                v-model="eventMap[eventData.id]"
                                                                :buttons="{startEvaluation:{rules:{attended:enrollmentMap[eventData.id] ? enrollmentMap[eventData.id].attended=='Y' : false, enrolled:enrolled.includes(eventData.id) ,finished:eventData.state=='finished', missingEval:eventData.postEventQuestions && !evaluations.includes(eventData.id)},label:{'en-ca':'Evaluation','fr-ca':'Évaluation'},doThis:startEvaluation}}"
                                                                :debug="debug"
                                                                @minutes_away="notify_starting"
                                                                @started="notify_started"
                                                                @more_details="more_details"
                                                                @enroll="enroll"
                                                                @withdraw="withdraw"
                                                                :language="ui.language ? ui.language : 'en-ca'"
                                                                :enrolled="enrolled.includes(eventData.id)"
                                                                :enrollmentRecord="enrollmentMap[eventData.id] ? enrollmentMap[eventData.id] : null"
                                                                :program="programMap[eventData.program_id]"
                                                                :missingEval="eventData.postEventQuestions && !evaluations.includes(eventData.id)"
                                                                :speakers="speakers"
                                                                :options="config.eventCard.options"
                                                                :bookings="bookings[eventData.id]"
                                                                class="events-card"
                                                            >
                                                                <template v-slot:actionButtons>
                                                                    <template v-if="section=='upcoming'">
                                                                        <v-btn :disabled="ui.busy" @click="enroll(eventData)" color="#6bab9b" class="white--text">
                                                                            <str index="events.buttons.enroll._text"/>
                                                                        </v-btn>
                                                                    </template>
                                                                    <v-btn @click="more_details(eventData)">
                                                                        <str index="events.buttons.details._text"/>
                                                                    </v-btn>
                                                                </template>


                                                            </EventCard>
                                                        </template>
                                                    </template>

                                                    <div v-if="!filteredEvents[lang][section].enrolled.length>0 && !filteredEvents[lang][section].not_enrolled.length>0" style="text-align: center; font-weight: bold; width:100%;">
                                                        <div v-if="section=='upcoming'" class='noEvents_text'>
                                                            <str v-if="form.filters.upcoming" index="events.results.no_events_upcoming"/>
                                                            <str v-else index="events.results.no_enrolled_events_upcoming"/>
                                                        </div>

                                                        <div v-else>
                                                            <str v-if="form.filters.past" index="events.results.no_events_past"/>
                                                            <str v-else index="events.results.no_enrolled_events_past"/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </v-expand-transition>
                                    </template>
                                </template>
                            </div>
                            <Loader v-if="!isAllTrue(initializedEvents)"/>
                        </v-tab-item>

                    </v-tabs-items>
                </v-col>
            </v-row>
        </v-container>
        <Loader v-else/>
    </div>

    <template v-if="selectedEvent">
        <EventDetails
            v-if="ready"
            @enroll="enroll"
            @withdraw="withdraw"
            :language="ui.language ? ui.language : 'en-ca'"
            :enrolled="enrolled.includes(selectedEvent.id)"
            :buttons="{startEvaluation:{rules:{attended:enrollmentMap[selectedEvent.id] ? enrollmentMap[selectedEvent.id].attended=='Y' : false, enrolled:enrolled.includes(selectedEvent.id) ,finished:selectedEvent.state=='finished', missingEval:selectedEvent.postEventQuestions && !evaluations.includes(selectedEvent.id)},label:{'en-ca':'Evaluation','fr-ca':'Évaluation'},doThis:startEvaluation}}"
            :program="programMap[selectedEvent.program_id]"
            :missingEval="selectedEvent.postEventQuestions && !evaluations.includes(selectedEvent.id)"
            :eventData="selectedEvent"
            :speakers="speakers"
            :options="config.eventCard.options"
            :bookings="bookings[selectedEvent.id]"
        >
            <template v-slot:actionButtons>
                <template v-if="enrollmentMap[selectedEvent.id] && enrollmentMap[selectedEvent.id].enrolled=='Y'">
                    <!-- <v-btn color="green" style="color:white !important;">
                        <str index="events.buttons.enrolled._text"/>
                    </v-btn> -->
                </template>
                <template v-else>
                    <v-btn @click="enroll(selectedEvent)">
                        <str index="events.buttons.enroll._text"/>
                    </v-btn>
                </template>
            </template>



            <template v-slot:resourceButtons>
                <v-container v-if="selectedEvent.state!='finished'" style="max-width:700px;">
                    <v-row class='bottomButtons'>
                        <div>
                            <v-btn :disabled="!debug && !['minutes_away','started'].includes(selectedEvent.state)" :href="selectedEvent.zoom.join_url" target="_blank">
                                <str index="events.buttons.join._text"/>
                            </v-btn>
                            <v-btn @click="sendRequest('get',`/api/event/ics/${Hasher.encode(selectedEvent.id)}/${$store.getters.user.signInUserSession.accessToken.jwtToken}`)">
                                <str index="events.buttons.ics._text"/>
                            </v-btn>
                        </div>
                        <div>
                            <v-btn v-if="ui.withdraw!=selectedEvent.id" @click="ui.withdraw=selectedEvent.id">
                                <str index="events.buttons.withdraw._text"/>
                            </v-btn>
                            <template v-else>
                                <v-btn color="error" style="color: white !important;" @click="withdraw(selectedEvent)">
                                    <str index="buttons.confirm._text"/>
                                </v-btn>
                                <v-btn @click="ui.withdraw=null">
                                    <str index="buttons.cancel._text"/>
                                </v-btn>
                            </template>
                        </div>
                    </v-row>
                </v-container>

                <template v-if="selectedEvent.state=='finished' && enrollmentMap[selectedEvent.id] && enrollmentMap[selectedEvent.id].attended=='Y'">
                    <div style="margin-top: 30px;">
                        <v-btn color="green" style="color: white !important;" v-if="postEvent[selectedEvent.id].evaluation===false" @click="startEvaluation(selectedEvent)">
                            <str index="events.buttons.evaluation._text"/>
                        </v-btn>
                        <v-btn v-else-if="enrollmentMap[selectedEvent.id].step_states && enrollmentMap[selectedEvent.id].step_states[1]" @click="startEvaluation(selectedEvent)">
                            <str index="events.buttons.certificate._text"/>
                        </v-btn>
                    </div>
                </template>

            </template>
        </EventDetails>
        <Loader v-else/>

    </template>

    <v-snackbar
      v-model="ui.snackbar.display"
    >
      {{ ui.snackbar.message }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="ui.snackbar.display = false; ui.snackbar.message=null"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <v-dialog v-model="ui.enrollment_success.display" width="500" style="overflow-y: visible !important;">
        <div style="position: relative;">

        <template  v-if="ui.enrollment_success.display && enrollment_selection">

            <v-card>
                <div style="width: 100%; border: 1px solid green;background-color: green;height: 15px; margin-bottom: 35px;"/>

                <v-card-text style="text-align: center; font-size: 18pt; color: black;">
                    <div style="width: 90%; margin: 0 auto;">
                        <p>
                            <str index="events.enrollment.success.thanks._text"/>
                        </p>

                        <br>

                        <p style="font-weight: 300; line-height: 30px;">
                            <str index="events.enrollment.success.msg_part_1" style="color: green;"/>
                            <br>
                                <b>{{enrollment_selection.title[enrollment_selection.language]}}</b>
                            <br>
                            <str index="events.enrollment.success.msg_part_2" style="color: green;"/>
                        </p>

                        <v-btn style="margin: 35px;color: #00A0BE;" rounded depressed @click="sendRequest('get',`/api/event/ics/${Hasher.encode(enrollment_selection.id)}/${$store.getters.user.signInUserSession.accessToken.jwtToken}`)">
                            <str index="events.buttons.ics._text"/>
                            <v-icon right>
                                mdi-calendar-export
                            </v-icon>
                        </v-btn>
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="ui.enrollment_success.display=false">
                        <v-icon left>mdi-close-circle</v-icon>
                        <str index="buttons.close"/>
                    </v-btn>
                </v-card-actions>
            </v-card>

            <div class="programDecal">
                <div class="programDecal-container">
                    <div class="programDecal-front-fold"/>
                    <div class="programDecal-back-fold"/>
                    <v-icon color="white" style="position: relative; left: 50%; top: 50%; transform: translate(-50%,-50%)">
                        mdi-check-bold
                    </v-icon>
                </div>
            </div>

            <div v-html="cssOverwrite"/>
        </template>

        </div>

    </v-dialog>

    <v-dialog v-model="ui.editor" persistent>
        <v-card>
            <v-card-actions>
                <v-btn @click="ui.editor=false">Close</v-btn>
            </v-card-actions>
            <v-card-text>
                <!-- <KeepAlive> -->
                    <Editor v-if="ui.editor"/>
                <!-- </KeepAlive> -->
            </v-card-text>
        </v-card>
    </v-dialog>

    <!-- <v-dialog v-model="ui.busy" persistent> -->
        <Loader v-if="ui.busy" class="busy"/>
    <!-- </v-dialog> -->
</div>
</template>

<script>
/*
Access to pre-recorded sessions
*/
let cache = require('@/plugins/cache.js')
import Hashids from 'hashids'
let {Hasher} = require('@/plugins/hasher.js')
import EventCard from '@/components/Events/EventCard'
import Filters from '@/components/Events/Filters'
import EventDetails from '@/components/Events/EventDetails'
// import EventActions from "@/components/Events/EventActions.vue"
import EventResources from "@/components/Events/EventResources.vue"
import Editor from "@/components/Events/Editor/Editor.vue"
import Loader from "@/components/Loader.vue"

export default {
    title: "Events",
    created: function(){
        // this.Hasher = new Hashids('Temporary Sald')
        this.Hasher = new Hasher(Hashids, this.$store.getters.hasherSalt)
    },
    mounted: function(){
        this.init()
    },
    components: {
        EventCard,
        EventDetails,
        // EventActions,
        EventResources,
        Filters,
        Editor,
        Loader
    },
    data: function(){
        return {
            ready: false,
            form: {
                filters: {
                    sortBy: null,
                    search: {
                        events: null,
                        programs: null
                    },
                    events: null,
                    enrolled: false,
                    upcoming: true,
                    past: false,
                    dates: []
                }
            },
            config: {
                event_sections: ['upcoming','past'],
                eventCard: {
                    options: {hide:['program-title'], bookings: false}
                }
            },
            programs: [],
            eventSearchSource: {},
            ui: {
                busy: false,
                language: null,
                filterDates: null,
                snackbar: {
                    display: false,
                    message: null
                },
                editor: false,
                enrollment_success: {
                    display: false
                },
                withdraw: null
            },
            speakers: null,
            enrolled: null,
            enrollmentMap: {},
            bookings: null,
            evaluations: [],
            missingEvaluations: [],
            timers: {
                getMissingEvaluations: null,
                filters: null
            },
            Hasher: undefined,
            enrollment_selection: null,
            postEvent: {
                completed: false,
                evaluation: false
            },
            filteredEvents: {
                'en-ca': {
                    past: {
                        enrolled: [],
                        not_enrolled: []
                    },
                    upcoming: {
                        enrolled: [],
                        not_enrolled: []
                    }
                },
                'fr-ca': {
                    past: {
                        enrolled: [],
                        not_enrolled: []
                    },
                    upcoming: {
                        enrolled: [],
                        not_enrolled: []
                    }
                }
            },
            initializedEvents: {}
        }
    },
    methods: {
        openZoom: function(url){
            return window.open(url, '_blank');
        },
        isAllTrue: function(object){
            if(typeof object=='object' && object!=null){
                for(let i in object){
                    if(!object[i]){
                        return false
                    }
                }
                return true
            }else{
                return false
            }
        },
        EventCardInitialized: function(eventID){
            this.initializedEvents[eventID] = true
            this.filterEvents(0)
        },
        init: async function(){
            let self = this
            await this.getData()
            await this.getEnrolled()
            // await this.getEvaluations()
            await this.getBookings()

            if(this.$route.name=='Events' && !this.$route.params.language){
                this.$router.push({name:'Events',params:{language:this.language}})
            }
            this.ui.language = this.$route.params.language
            self.filterEvents()
            self.ready = true
        },
        toggleMyEvents: function(){
            let form = this.form
            if(!form.filters.upcoming && !form.filters.past){
                form.filters.upcoming=true
                form.filters.past=false
            }else{
                form.filters.upcoming=false
                form.filters.past=false
            }
        },
        getData: async function(){
            let self = this
            function getPrograms(){
                return new Promise((resolve, reject)=>{
                    let output = {
                        eventSearchSource: {},
                        programs: null,
                        speakers: {}
                    }
                    self.sendRequest('get','/api/getSpeakers').then((response)=>{
                        let speakers = response.data
                        for(let i=0; i<speakers.length; i++){
                            let speaker = speakers[i]
                            output.speakers[speaker.id] = speaker
                        }
                        self.sendRequest('get','/api/getPrograms').then((response)=>{
                            let programs = response.data
                            for(let i=0; i<programs.length; i++){
                                let program = programs[i]

                                for(let e=0; e<program.events.length; e++){
                                    let data=program.events[e]
                                    self.initializedEvents[data.id] = false
                                    data.state = undefined
                                    data.start_time = new Date(data.start_time)
                                    data.start_time = data.start_time.toString()
                                    data.end_time = new Date(data.end_time)
                                    data.end_time = data.end_time.toString()
                                    output.eventSearchSource[data.id] = {
                                        data,
                                        speakers: {}
                                    }

                                    for(let s=0; s<data.speakers.length; s++){
                                        let speaker_id = data.speakers[s]
                                        output.eventSearchSource[data.id].speakers[speaker_id] = output.speakers[speaker_id]
                                    }
                                    output.eventSearchSource[data.id] = JSON.stringify(output.eventSearchSource[data.id])
                                }
                            }
                            output.programs = programs
                            resolve(output)
                        })
                    })

                    // self.sendRequest('get','/api/getPrograms').then((response)=>{
                    //     let programs = response.data
                    //     for(let i=0; i<programs.length; i++){
                    //         let program = programs[i]

                    //         for(let e=0; e<program.events.length; e++){
                    //             let data=program.events[e]
                    //             self.initializedEvents[data.id] = false
                    //             data.state = undefined
                    //             data.start_time = new Date(data.start_time)
                    //             data.start_time = data.start_time.toString()
                    //             data.end_time = new Date(data.end_time)
                    //             data.end_time = data.end_time.toString()
                    //             output.eventSearchSource[data.id] = JSON.stringify(data)
                    //         }
                    //     }
                    //     output.programs = programs


                    //     self.sendRequest('get','/api/getSpeakers').then((response)=>{
                    //         let speakers = response.data
                    //         for(let i=0; i<speakers.length; i++){
                    //             let speaker = speakers[i]
                    //             output.speakers[speaker.id] = speaker
                    //         }
                    //         resolve(output)
                    //     })
                    // })
                })
            }
            let data = await getPrograms()

            for(let i=0; i<data.programs.length; i++){
                let program = data.programs[i]
                self.$set(self.programs, i, program)
            }
            self.speakers = data.speakers
            self.eventSearchSource = data.eventSearchSource
        },
        getEnrolled: async function(){
            let self = this
            function isAllTrue(object){
                if(typeof object=='object' && object!=null){
                    for(let i in object){
                        if(!object[i]){
                            return false
                        }
                    }
                    return true
                }else{
                    return false
                }
            }
            function getEnrolled(){
                return new Promise((resolve, reject)=>{
                    self.sendRequest('get','/api/event/enrolled').then((response)=>{
                        resolve(response.data)
                    },(error)=>{
                        reject(error)
                    })
                })
            }

            if(this.signedIn){
                getEnrolled().then((response)=>{
                    // self.enrolled = response
                    self.enrolled = []
                    self.enrollmentMap = {}                   
                    
                    for(let i=0; i<response.length; i++){
                        let item = response[i]
                        let completed = false                        
                        self.enrollmentMap[item.event_id] = item
                        self.postEvent[item.event_id] = {completed: false, evaluation: false}
                        if(item.enrolled=='Y'){
                            self.enrolled.push(item.event_id)
                            if(item.step_states && item.step_states[1]){
                                self.postEvent[item.event_id]['evaluation'] = true
                            }
                            if(isAllTrue(item.step_states) && item.attended=='Y'){
                                self.postEvent[item.event_id]['completed'] = true
                            }
                        }
                        
                    }
                    
                },(err)=>{
                    console.error(err)
                    self.enrolled = []
                })
            }else{
                self.enrolled = []
            }
        },
        // getEvaluations: async function(){
        //     function load(user_id){
        //         return new Promise((resolve, reject)=>{
        //             setTimeout(function(){
        //                 let cachedEvals = cache.storage.local.get('evaluations') ? cache.storage.local.get('evaluations')[user_id] : {}
        //                 let evaluations = []
        //                 for(let event_id in cachedEvals){
        //                     evaluations.push(parseInt(event_id,10))
        //                 }
        //                 resolve(evaluations)
        //             },200)
        //         })
        //     }

        //     this.evaluations = await load(this.$store.getters.userID)

        // },
        getBookings: async function(){
            let self = this
            async function getBookings(){
                return new Promise((resolve, reject)=>{
                    self.sendRequest('get','/api/event/bookings').then((response)=>{
                        resolve(response.data)
                    })
                })
            }

            getBookings().then((response)=>{
                self.bookings = response
            },(err)=>{
                console.error(err)
                self.bookings = {}
            })

        },
        notify_starting: function(eventData){
            let notifications = this.$store.getters.notifications
            notifications.push({
                type: 'eventStarting',
                id: eventData.id,
                label: 'This meeting is starting soon: '+eventData.title[this.language],
                eventData,
                icon: {
                    type: 'icon',
                    value: 'mdi-human-queue'
                },
                doThis: this.more_details
            })
        },
        notify_started: function(eventData){
            let notifications = this.$store.getters.notifications
            notifications.push({
                type: 'eventStarted',
                id: eventData.id,
                label: 'This meeting has started: '+eventData.title[this.language],
                eventData,
                icon: {
                    type: 'icon',
                    value: 'mdi-play-circle-outline'
                },
                doThis: this.more_details
            })
        },
        notifyMissingEvaluation: function(){
            let notifications = this.$store.getters.notifications
            let missingEvaluations = this.missingEvaluations

            for(let m=0; m<missingEvaluations.length; m++){
                let eventData = missingEvaluations[m]

                notifications.push({
                    type: 'missingEvaluation',
                    id: eventData.id,
                    label: 'Please complete evaluation for: '+eventData.title[this.language],
                    eventData,
                    icon: {
                        type: 'icon',
                        value: 'mdi-clipboard-text-outline'
                    },
                    doThis: this.startEvaluation
                })

            }

        },
        startEvaluation: function(eventData){
            let self = this
            this.$router.push({
                name: 'Evaluation',
                params: {
                    event_id: this.Hasher.encode(eventData.id),
                    question_set_id: this.Hasher.encode(eventData.postEventQuestions)
                }
            })
            setTimeout(function(){
                self.$store.commit('language', self.ui.language)
            },800)


        },
        timeUntil: function(earlier_time, later_time){
            let a = new Date(earlier_time)
            let b = new Date(later_time)
            let diff = b - a

            if(diff>=0){
                let days = Math.floor(diff/1000/60/60/24)
                let hours = Math.floor((diff - (days*24*60*60*1000)) / 1000/60/60)
                let minutes = Math.floor((diff - (days*24*60*60*1000) - (hours*60*60*1000)) /1000/60)
                let seconds = Math.floor((diff - (days*24*60*60*1000) - (hours*60*60*1000) - (minutes*60*1000)) /1000)

                return {
                    days,
                    hours,
                    minutes,
                    seconds
                }
            }else{
                return false
            }
        },
        enroll: async function(eventData){
            let self = this
            self.ui.withdraw = null
            function enroll(eventData){
                return new Promise((resolve, reject)=>{
                    self.sendRequest('put',`/api/event/enroll/${self.Hasher.encode(eventData.id)}`,{time_zone:self.$store.getters.time_zone}).then((response)=>{
                        resolve(response)
                    })
                })
            }

            if(this.signedIn){
                self.ui.busy = true
                if(eventData.seats.max.value==0 || (eventData.seats.max.value!=0 && this.bookings[eventData.id]<eventData.seats.max.value)){
                    let enrollResponse = await enroll(eventData)
                    if(enrollResponse.status==200 && enrollResponse.data.results){
                        self.eventMap[eventData.id].zoom = enrollResponse.data.eventData.zoom
                        self.ui.enrollment_success.display = true
                        self.enrollment_selection = self.eventMap[eventData.id]
                    }
                    await this.getEnrolled()
                    await this.getBookings()
                }else{
                    this.ui.snackbar.display = true
                    this.ui.snackbar.message = "This event has reached maximum capacity"
                }
                self.filterEvents()
                self.$forceUpdate()
                self.ui.busy = false
            }else{
                this.more_details(eventData)
                let self = this
                setTimeout(function(){
                    cache.storage.local.set('pathAfterLogin',self.$route.fullPath)
                    self.$router.push({name:'SignIn'})
                },800)
            }
        },
        withdraw: async function(eventData){
            let self = this
            function withdraw(eventData){
                return new Promise((resolve, reject)=>{
                    self.sendRequest('patch',`/api/event/withdraw/${self.Hasher.encode(eventData.id)}`).then((response)=>{
                        resolve(response)
                    })
                })
            }
            self.ui.busy = true
            await withdraw(eventData)
            await this.getEnrolled()
            await this.getBookings()
            self.filterEvents()
            self.$forceUpdate()
            self.ui.busy = false
        },
        getMissingEvaluations: function(){
            let self = this
            clearTimeout(self.timers.getMissingEvaluations)
            self.timers.getMissingEvaluations = setTimeout(function(){
                let programs = self.programs
                let enrolled = self.enrolled
                self.missingEvaluations = []
                    for(let p=0; p<programs.length; p++){
                        let program = programs[p]
                        for(let e=0; e<program.events.length; e++){
                            let eventData = program.events[e]
                            if(enrolled[eventData.id] && eventData.state=='finished' && !self.evaluations.includes(eventData.id)){
                                self.missingEvaluations.push(eventData)
                                self.notifyMissingEvaluation()
                            }
                        }
                    }
            },800)
        },
        more_details: function(eventData){
            this.$router.push({
                name:'Event',
                params:{
                    language: this.$route.params.language,
                    event_id: this.Hasher.encode(eventData.id)
                }
            })
        },
        filterEvents: async function(delay=1000){

            function eventFilter(filters, eventData, section, enrollType){
                let debug = self.debug
                let eventID = eventData.id
                let searchSource = self.eventSearchSource[eventData.id].toUpperCase()
                if(filters && (self.enrolled!=null) ){
                    let isEnrolled = self.enrolled.includes(eventData.id)
                    let allowEnrollType = enrollType=='enrolled' ? isEnrolled : !isEnrolled
                    let allowSection = (section=='upcoming' && (filters.upcoming || isEnrolled) && eventData.state!='finished') || (section=='past' && (filters.past || isEnrolled) && (eventData.state=='finished' || eventData.state==undefined))



                    let allowDate = true
                    if(allowEnrollType && allowSection && filters.dates.length==2){
                        let dates = JSON.parse(JSON.stringify(filters.dates)).sort()
                        let splitDate1 =  dates[0].split('-')
                        let date1 = new Date(parseInt(splitDate1[0],10), parseInt(splitDate1[1],10)-1, parseInt(splitDate1[2],10))

                        let splitDate2 =  dates[1].split('-')
                        let date2 = new Date(parseInt(splitDate2[0],10), parseInt(splitDate2[1],10)-1, parseInt(splitDate2[2],10), 23, 59, 59, 99)

                        let startDate = new Date(eventData.start_time)

                        allowDate = date1<=startDate && startDate<=date2
                    }


                    let searchIndex = 0
                    let searchString = (filters.search!=null && typeof filters.search=='object') ? filters.search.events : null
                    let executeSearch = (typeof searchString=='string' && searchString.length>0)
                    if(allowEnrollType && allowSection && allowDate && executeSearch){
                        searchIndex = searchSource.indexOf(searchString.toUpperCase()) //this.eventSearchSource[eventData.id].toUpperCase().indexOf(searchString.toUpperCase())
                    }



                    let result = searchIndex!=-1 && allowSection && allowEnrollType && allowDate// && (eventData.publish=='Y' && eventData.cancelled=='N')



                    if(debug && [4,14].includes(eventData.id)){
                        console.log({
                            filters,
                            eventID,
                            section,
                            allowProcess: {
                                allowSection,
                                allowEnrollType
                            },
                            dateFilter: {
                                allowDate
                            },
                            searchFilter: {
                                searchIndex,
                                searchString,
                                result: executeSearch ? searchIndex!=-1 : null,
                            },
                            result
                        })
                    }

                    return result
                }else{
                    return true
                }
            }

            function sortByTitleAscending(a,b){
                return a.title[a.language].localeCompare(b.title[b.language])
            }

            function sortByTitleDescending(a,b){
                return b.title[b.language].localeCompare(a.title[a.language])
            }

            function sortByNewest(a,b){
                let t1 = new Date(a.start_time)
                let t2 = new Date(b.start_time)
                let result = t2.getTime()-t1.getTime()
                return result
            }

            function sortByOldest (a,b){
                let t1 = new Date(a.start_time)
                let t2 = new Date(b.start_time)
                let result = t1.getTime()-t2.getTime()
                return result
            }

            let self = this
            clearTimeout(self.timers.filters)
            self.timers.filters = setTimeout(function(){

                let filters = self.form.filters
                let event_sections = self.config.event_sections
                let languages = self.languages
                let output = {}

                for(let l=0; l<languages.length; l++){
                    let lang = languages[l]
                    output[lang] = {}

                    for(let i=0; i<event_sections.length; i++){
                        let section = event_sections[i]
                        output[lang][section] = {
                            enrolled: [],
                            not_enrolled: []
                        }

                        for(let type in output[lang][section]){
                            for(let eventID in self.eventMap){
                                eventID = parseInt(eventID, 10)

                                let eventData = self.eventMap[eventID]
                                let enrolled = false //typeof self.enrolled=='object' && self.enrolled!=null && self.enrolled.length ? self.enrolled.includes(eventID) : false
                                try {
                                    enrolled = self.enrolled!=null ? self.enrolled.includes(eventID) : false
                                } catch (error) {
                                    console.log('filter error',{
                                        enrolled: self.enrolled,
                                        error
                                    })
                                    enrolled = false
                                }
                                let classification = {
                                    upcoming: section=='upcoming' && eventData.state!='finished',
                                    past: section=='past' && (eventData.state=='finished' || !eventData.state),
                                    output: null
                                }

                                if(classification.upcoming){

                                    if(eventData.language==lang && type=='enrolled' && enrolled){
                                        let result = eventFilter(filters, eventData, section, type)
                                        classification.result = lang + ' > ' + section + ' > ' + type + ': '+ result
                                        if(result){
                                            output[lang][section][type].push(eventData)
                                        }
                                    }
                                    if(eventData.language==lang && type=='not_enrolled' && !enrolled){
                                        let result = eventFilter(filters, eventData, section, type)
                                        classification = lang + ' > ' + section + ' > ' + type + ': '+ result
                                        if(result){
                                            output[lang][section][type].push(eventData)
                                        }
                                    }

                                }
                                if(classification.past){
                                    let scenario = {
                                        enrolled: eventData.language==lang && type=='enrolled' && enrolled,
                                        not_enrolled: eventData.language==lang && type=='not_enrolled' && !enrolled
                                    }

                                    if(scenario.enrolled){
                                        let result = eventFilter(filters, eventData, section, type)
                                        classification.result = lang + ' > ' + section + ' > ' + type + ': '+ result
                                        if(result){
                                            output[lang][section][type].push(eventData)
                                        }
                                    }
                                    if(scenario.not_enrolled){
                                        let result = eventFilter(filters, eventData, section, type)
                                        classification.result = lang + ' > ' + section + ' > ' + type + ': '+ result
                                        if(result){
                                            output[lang][section][type].push(eventData)
                                        }
                                    }

                                }



                            }
                        }

                        if(filters.sortBy=='title_ascending'){
                            output[lang][section].enrolled.sort(sortByTitleAscending)
                            output[lang][section].not_enrolled.sort(sortByTitleAscending)
                        }

                        if(filters.sortBy=='title_descending'){
                            output[lang][section].enrolled.sort(sortByTitleDescending)
                            output[lang][section].not_enrolled.sort(sortByTitleDescending)
                        }

                        if(filters.sortBy=='newest'){
                            output[lang][section].enrolled.sort(sortByNewest)
                            output[lang][section].not_enrolled.sort(sortByNewest)
                        }

                        if(filters.sortBy=='oldest'){
                            output[lang][section].enrolled.sort(sortByOldest)
                            output[lang][section].not_enrolled.sort(sortByOldest)
                        }
                    }
                }
                self.filteredEvents = output

            },delay)


        },
        filterDates: function(range){
            function toDigits(input){
                if(input<10){
                    return "0"+input
                }else{
                    return input
                }
            }

            function formatDate(date){
                return date.getFullYear() + '-' + toDigits(date.getMonth()+1) + '-' + toDigits(date.getDate())
            }

            if(this.ui.filterDates != range){
                let dt1 = new Date()
                let dt2 = new Date()

                switch(range){
                    case "Today":
                        dt1.setDate( dt1.getDate() );
                        dt2.setDate( dt2.getDate() );
                    break;

                    case "This Week":
                        dt1.setDate(dt1.getDate()-(dt1.getDay()-1))
                        dt2.setDate(dt2.getDate()+(7-dt2.getDay()))
                    break;

                    case "Next Week":
                        dt1.setDate(dt1.getDate()+(8-dt1.getDay()))
                        dt2.setDate(dt2.getDate()+(14-dt2.getDay()))
                    break;

                    case "This Month":
                        dt2 = new Date(dt1.getFullYear(), dt1.getMonth()+1, 0)
                    break;
                }

                this.ui.filterDates = range
                this.form.filters.dates = [
                    formatDate(dt1),
                    formatDate(dt2)
                ]

            }else{
                this.ui.filterDates = null
                this.form.filters.dates = []
            }


        }
    },
    computed: {
        allowAccess: function(){
            let groups = this.$store.getters.signedIn && this.$store.getters.user.signInUserSession.accessToken.payload['cognito:groups'] ? this.$store.getters.user.signInUserSession.accessToken.payload['cognito:groups'] :[]
            let allowed = ['SuperAdmin','staff']

            for(let i=0; i<groups.length; i++){
                let group = groups[i]
                if(allowed.includes(group)){
                    return true
                }
            }

            return false
        },
        signedIn: function(){
            return this.$store.getters.signedIn
        },
        debug: function(){
            return this.$store.getters.debug
        },
        language: function(){
            return this.$store.getters.language
        },
        languages: function(){
            return this.$store.getters.languages
        },
        programMap: function(){
            let array = this.programs
            let output = {}
            for(let i=0; i<array.length; i++){
                let item = array[i]
                output[item.id] = item
            }
            return output
        },
        eventMap: function(){
            let programs = this.programs
            let output = {}
            for(let p=0; p<programs.length; p++){
                let program = programs[p];

                for(let e=0; e<program.events.length; e++){
                    let programEvent = program.events[e]
                    output[programEvent.id] = programEvent
                }
            }

            return output
        },
        selectedEvent: function(){
            let eventID = this.Hasher.decode(this.$route.params.event_id)
            if(eventID){
                return this.eventMap[eventID] ? this.eventMap[eventID] : null
            }
            return null
        },
        autocompleteItems: function(){
            let language = this.ui.language
            let programs = this.programs
            let output = []

            for(let p=0; p<programs.length; p++){
                let program = programs[p]
                output.push({type:'program', value:program.title[language]})
                for(let e=0; e<program.events.length; e++){
                    let eventData = program.events[e]
                    output.push({type:'event', value:eventData.title[language]})
                }
            }

            return output
        },
        cssOverwrite: function(){
            return `
            <style type='text/css'>
                .v-dialog.v-dialog--active{
                    overflow-y: visible !important;
                }
            </style>
            `
        },
    },
    watch: {
        language: function(){
            this.$router.push({name:'Events',params:{language:this.language}})
            this.ui.language = this.language
        },
        signedIn: function(){
            if(!this.signedIn){
                this.init()
            }
        },
        form: {
            deep: true,
            handler: function(){
                this.filterEvents()
            }
        }
    }
}
</script>

<style scoped lang="scss">
.busy{
    position: fixed;
    z-index: 1000;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-100%);
}

.tabContainer{
    background-color: $primary-light-1 !important;
    padding-top: 30px;
    height: auto !important;
}

.v-tab{
    padding: 20px 50px 20px 50px;
    width: 260px;
    margin: 2px;
    font-weight: bold;
    background-color: $primary-light-2;
    color: slategray;
    height: 55px;
    position: relative;
    top: 7px;
}


.v-tab--active{
    background-color: $tab-canvas-1;
    height: 80px;
    top: -10px;
    color: #000000;
}

.v-tab--active .tab-text{
    color: slategray;
}

.filterDates-button{
    cursor: pointer;
    display: flex;
    width: 100px;
    height: 100px;
    background-color: $primary-light-3;
    color: $primary;
    margin: 8px;
    border: 1px solid lightgrey;
    transition: all 0.2s;
}

.filterDates-button:hover{
    border: 1px solid white;
}

    .filterDates-button div{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
    .filterDates-button.active{
        background-color: $secondary;
        color: white;
    }

.events-section-past{
    background-color: $primary-light-4;
}


.events-state-treatment{
    border: 1px solid $secondary;
    width: 600px;
    position: relative;
    top: 25px;
    z-index: 1;

    @media(max-width:575px){
        display: none;
    }
}

.events-state-title{
    position: relative;
    width: 300px;
    background-color: $tab-canvas-1;
    font-size: 2em;
    font-weight: 100;
    z-index: 2;

    @media(max-width:575px){
        width: auto;
    }
}
.events-section-past .events-state-title{
    background-color: $primary-light-4;
}

.events-container{
    display: flex;
    gap: 2em;
    align-items: start;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    padding-bottom: 50px;
}

.noEvents_text {
    padding: 0 12px;
}

.events-card{
    min-height: 200px;
    width: 50%;
    flex-basis: auto;
    // min-width: 528px;
    // width: 48%;
}

.speaker-container{
    margin: 25px;
    padding: 15px;
    display: flex;
    gap: 2em;
    align-items: start;
    justify-content: space-evenly;
    flex-direction: row;
    flex-wrap: wrap;
}
.speaker-container .v-avatar{
    border: 5px solid $primary;
    background-color: $primary-light-4 !important;
    color: white;
    margin: 0px 15px 0px 15px;
}
    .enrolled .speaker-container .v-avatar{
        border: 5px solid $secondary;
    }


.programDecal{
    position: absolute;
    top: 0px;
    right: 25px;
}

.programDecal-container{
    position: relative;
    background-color: $primary;
    width: 50px;
    height: 60px;
}

.programDecal-back-fold{
    position: absolute;
    top: -8px;
    right: -6px;
    width: 10px;
    height: 8px;
    background-color: blue;
    z-index: 1;
}

.programDecal-front-fold{
    position: absolute;
    top: -8px;
    right: -3px;
    transform: skewX(-40deg);
    width: 100%;
    height: 8px;
    background-color: $primary;
    z-index: 2;
}

@media only screen and (max-width: 1024px){
    .events-card{
        width: 100%;
    }
}
@media only screen and (max-width: 800px){
    .v-tab{
        padding: 15px 10px 15px 10px;
        width: 50%;
        height: 55px;
        top: 0px;
    }

    .events-card{
        width: 100%;
        margin: 0px;
    }
}
.selected-tab {
    background-color: $tab-canvas-1;
}
.resource-btn-container {
    background: $resource-canvas-1;
    padding: 0;
}
.cta {
    background-color: $primary-light-1 !important;
}
.v-btn {
    text-transform: capitalize;
}
.withdraw-container {
    max-width: 125px;
    cursor: pointer;
    font-size: 15px;
    line-height: .95;
    font-weight: 500;
}
.withdraw-btn {
    padding: 0px !important;
    border: none;
    background: transparent;
    color: #000000;
    font-weight: 700;
    width: 100%;
}

.bottomButtons {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    margin: 0;

    @media(max-width:767px){
        flex-flow: column;
    }

    > div {
        width: 50%;

        @media(max-width:767px){
            width: 100%;
        }

        &:nth-of-type(2) {
            margin: 0 auto;
        }
    }
}

</style>
